/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import poolsConfig from "config/constants/pools";
import { fetchPoolsBlockLimits, fetchPoolsTotalStaking } from "./fetchPools";
import {
  fetchPoolsAllowance,
  fetchUserBalances,
  fetchUserStakeBalances,
  fetchUserPendingRewards,
} from "./fetchPoolsUser";
import { PoolsState, Pool } from "../types";

const initialState: PoolsState = { data: [...poolsConfig] };

export const PoolsSlice = createSlice({
  name: "Pools",
  initialState,
  reducers: {
    setPoolsPublicData: (state, action) => {
      const livePoolsData: Pool[] = action.payload;
      state.data = state.data.map((pool) => {
        const livePoolData = livePoolsData.find(
          (entry) => entry.sousId === pool.sousId
        );
        return { ...pool, ...livePoolData };
      });
    },
    setPoolsUserData: (state, action) => {
      const userData = action.payload;
      state.data = state.data.map((pool) => {
        const userPoolData = userData.find(
          (entry) => entry.sousId === pool.sousId
        );
        return { ...pool, userData: userPoolData };
      });
    },
    updatePoolsUserData: (state, action) => {
      const { field, value, sousId } = action.payload;
      const index = state.data.findIndex((p) => p.sousId === sousId);
      state.data[index] = {
        ...state.data[index],
        userData: { ...state.data[index].userData, [field]: value },
      };
    },
  },
});

// Actions
export const { setPoolsPublicData, setPoolsUserData, updatePoolsUserData } =
  PoolsSlice.actions;

// Thunks
export const fetchPoolsPublicDataAsync = () => async (dispatch) => {
  const blockLimits = await fetchPoolsBlockLimits();
  const totalStakings = await fetchPoolsTotalStaking();
  // const infos = await fetchPools()
  const liveData = poolsConfig.map((pool) => {
    const totalStaking = totalStakings.find(
      (entry) => entry.sousId === pool.sousId
    );
    // const info = infos.find((entry) => entry.sousId === pool.sousId)
    const blockLimit = blockLimits.find(
      (entry) => entry.sousId === pool.sousId
    );
    return {
      ...blockLimit,
      ...totalStaking,
    };
  });

  dispatch(setPoolsPublicData(liveData));
};

export const fetchPoolsUserDataAsync = (account) => async (dispatch) => {
  const allowances = await fetchPoolsAllowance(account);
  const stakingTokenBalances = await fetchUserBalances(account);
  const stakedBalances = await fetchUserStakeBalances(account);
  const pendingRewards = await fetchUserPendingRewards(account);
  // const canHarvests = await fetchUserCanHarvests(account)
  const userData = poolsConfig.map((pool) => ({
    sousId: pool.sousId,
    allowance: allowances[pool.sousId],
    stakingTokenBalance: stakingTokenBalances[pool.sousId],
    stakedBalance: stakedBalances[pool.sousId],
    pendingReward: pendingRewards[pool.sousId],
    canHarvest: true,
  }));

  dispatch(setPoolsUserData(userData));
};

export const updateUserAllowance =
  (sousId: string, account: string) => async (dispatch) => {
    const allowances = await fetchPoolsAllowance(account);
    dispatch(
      updatePoolsUserData({
        sousId,
        field: "allowance",
        value: allowances[sousId],
      })
    );
  };

export const updateUserBalance =
  (sousId: string, account: string) => async (dispatch) => {
    const tokenBalances = await fetchUserBalances(account);
    dispatch(
      updatePoolsUserData({
        sousId,
        field: "stakingTokenBalance",
        value: tokenBalances[sousId],
      })
    );
  };

export const updateUserStakedBalance =
  (sousId: string, account: string) => async (dispatch) => {
    const stakedBalances = await fetchUserStakeBalances(account);
    dispatch(
      updatePoolsUserData({
        sousId,
        field: "stakedBalance",
        value: stakedBalances[sousId],
      })
    );
  };

export const updateUserPendingReward =
  (sousId: string, account: string) => async (dispatch) => {
    const pendingRewards = await fetchUserPendingRewards(account);
    dispatch(
      updatePoolsUserData({
        sousId,
        field: "pendingReward",
        value: pendingRewards[sousId],
      })
    );
  };

export default PoolsSlice.reducer;
